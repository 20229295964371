import { TextEncoder } from 'text-encoding';
function mapApiOptionValueToString(value) {
    switch (typeof value) {
        case 'boolean': {
            return value ? '1' : '0';
        }
        case 'number': {
            return value.toString();
        }
        case 'object': {
            if (Array.isArray(value)) {
                return value.toString();
            }
            throw new Error(`Unsupported api option value: ${value}`);
        }
        default: {
            return value;
        }
    }
}
export function apiOptionsToSearchParams(apiOptions) {
    const searchParams = Object.entries(apiOptions).reduce((params, [key, value]) => {
        switch (key) {
            case 'resolve':
            case 'resolveInSeparateList': {
                params.append(key, dataToBase64(value));
                break;
            }
            default: {
                params.append(key, mapApiOptionValueToString(value));
            }
        }
        return params;
    }, new URLSearchParams());
    return searchParams;
}
/**
 * urlSearchParams.toString() encodes the values of urlSearchParams. This method prevents the encoding.
 * @param urlSearchParams
 * @returns the unencoded search params string
 */
export function urlSearchParamsToUnencodedString(urlSearchParams) {
    return Array.from(urlSearchParams.entries())
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
}
export const dataToBase64 = (data) => {
    if (data != null) {
        const encoder = new TextEncoder();
        const utf8Bytes = encoder.encode(JSON.stringify(data));
        const base64String = btoa(String.fromCharCode(...utf8Bytes));
        return base64String;
    }
    return '';
};
