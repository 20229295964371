import isHerbicide from '@/products/herbizides/isHerbicide';
import { filterByApprovalTimes, filterByCropIds, filterCompanySpecificCropId, } from './getterUtils/compatiblePesticideIndicationsFilter';
import dropdownItemName from './getterUtils/dropdownDisplayName';
const moduleGetters = {
    compatiblePesticideIndications: (state, getters, rootState, rootGetters) => (pesticideId, cropIds, activityTimeStarts) => {
        var _a, _b;
        const globalCropIds = cropIds.filter((cropId) => filterCompanySpecificCropId(cropId, rootGetters['products/findProductById']));
        return ((_b = (_a = state.data[pesticideId]) === null || _a === void 0 ? void 0 : _a.filter((pesticideIndication) => filterByCropIds(pesticideIndication, globalCropIds)).filter((pesticideIndication) => filterByApprovalTimes(pesticideIndication, activityTimeStarts))) !== null && _b !== void 0 ? _b : []);
    },
    findById: (state) => (pesticideId, pesticideIndicationId) => {
        const pesticideIndications = state.data[pesticideId];
        if (!pesticideIndications)
            return undefined;
        return Object.values(state.data[pesticideId]).find((indication) => indication.id === pesticideIndicationId);
    },
    dropdownItem: (state, getters, rootState) => (pesticideId, pestId, pesticideIndicationId) => {
        const pest = rootState.pests.data[pestId];
        const pesticideIndication = getters.findById(pesticideId, pesticideIndicationId);
        if (!pest || !pesticideIndication)
            return { id: '', name: '' };
        const unit = pesticideIndication.maxAmountUnitId
            ? rootState.units.data[pesticideIndication.maxAmountUnitId]
            : undefined;
        return { id: `${pest.id}_${pesticideIndication.id}`, name: dropdownItemName(pest, pesticideIndication, unit) };
    },
    dropdownItems: (state, getters, rootState) => (pesticideId, cropIds, activityTimeStarts) => {
        const items = [];
        const compatiblePesticideIndications = getters.compatiblePesticideIndications(pesticideId, cropIds, activityTimeStarts);
        compatiblePesticideIndications.forEach((pesticideIndication) => {
            pesticideIndication.pestIds.forEach((pestId) => {
                const pest = rootState.pests.data[pestId];
                if (pest) {
                    items.push(getters.dropdownItem(pesticideId, pestId, pesticideIndication.id));
                }
            });
        });
        return [{ items }];
    },
    dropdownItemsForProductAndField: (state, getters, rootState, rootGetters) => (productId, fieldId, activityTimeStarts) => {
        // move from getItems to here
        const product = rootGetters['products/findProductById'](productId);
        if (!product)
            return null;
        const productCategories = rootState.productCategories.data;
        if (!isHerbicide(product, productCategories))
            return null;
        const { cropId } = rootState.fields.data[fieldId];
        if (!cropId)
            return null;
        return getters.dropdownItems(product.id, [cropId], activityTimeStarts);
    },
};
export default moduleGetters;
