import { validateProcessOrderName } from './common';
import { initialStateUserInfo } from './state';
const mutations = {
    setJwtAuth(state, jwtAuth) {
        state.jwtAuth = jwtAuth;
    },
    startFetching(state, key) {
        state[key] = true;
    },
    endFetching(state, key) {
        state[key] = false;
    },
    addObserver(state, resolve) {
        state.observers = [...state.observers, resolve];
    },
    clearObservers(state) {
        state.observers = [];
    },
    setLoggedIn(state, { userId }) {
        state.loggedIn = true;
        state.user.id = userId;
    },
    setLoggedOut(state) {
        state.loggedIn = false;
        Object.assign(state, initialStateUserInfo());
    },
    setUserCompanies(state, userCompanies) {
        state.userCompanies = userCompanies;
        state.companiesById = userCompanies.reduce((companiesById, userCompany) => (Object.assign(Object.assign({}, companiesById), { [userCompany.company.id]: userCompany.company })), {});
        state.userInfoLoaded = true;
    },
    setUser(state, user) {
        state.user = user;
    },
    updateUser(state, data) {
        Object.keys(data).forEach((key) => {
            if (key === 'id' || key === 'guid') {
                return;
            }
            // @ts-ignore
            state.user[key] = data[key];
        });
        state.user.displayName = `${state.user.firstname} ${state.user.lastname}`;
    },
    setCurrentCompaniesAndProcessOrderName(state, { companies, processOrderName }) {
        state.currentCompanies = companies;
        state.currentProcessOrderName = validateProcessOrderName(companies, processOrderName);
    },
    setCurrentProcessOrderName(state, processOrderName) {
        state.currentProcessOrderName = validateProcessOrderName(state.currentCompanies, processOrderName);
    },
    /**
     * Use updateCompanyById instead #326
     *
     * @deprecated
     */
    updateCurrentCompany(state, { id, data }) {
        Object.keys(data).forEach((key) => {
            if (key === 'id' || key === 'guid') {
                return;
            }
            // @ts-ignore
            state.companiesById[id][key] = data[key];
        });
        state.currentCompanies = state.currentCompanies.map((company) => {
            if (company.id !== id) {
                return company;
            }
            return state.companiesById[id];
        });
    },
};
export default mutations;
