import { subscribableStore } from './common';
import initialState from './initialState';
const vuexModule = {
    namespaced: true,
    state: initialState(),
    mutations: Object.assign({}, subscribableStore.mutations),
    actions: Object.assign({}, subscribableStore.actions),
    getters: Object.assign({}, subscribableStore.getters),
};
export default vuexModule;
