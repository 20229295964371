var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Vue from 'vue';
import loadAllPages from '@/shared/api/farmdokRestApi/utils/loadAllPages';
import subscribableData from '@/shared/mixins/store/subscribableData/index';
import { availableFeatures } from '@/shared/storeDynamicFeatures';
export function fetchAll(context, axiosOptions) {
    return __awaiter(this, void 0, void 0, function* () {
        const { storagePlacesApi } = Vue.prototype.$api;
        const requestParameters = {
            companyId: context.rootGetters['auth/currentCompanyIds'][0],
        };
        const apiResponse = loadAllPages(storagePlacesApi, 'storagePlaceList', requestParameters, axiosOptions);
        return apiResponse;
    });
}
function fetchByIds(ids, context, axiosOptions) {
    return __awaiter(this, void 0, void 0, function* () {
        const { storagePlacesApi } = Vue.prototype.$api;
        const { data } = yield storagePlacesApi.storagePlaceObjects({ objectIds: ids.join(',') }, axiosOptions);
        return data;
    });
}
export const subscribableStore = subscribableData(fetchAll, fetchByIds, [availableFeatures.FEATURE_STOCK_MOVEMENT]);
