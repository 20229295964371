import isFirstRowOfEntry from './isFirstRowOfEntry';
import isLastRowOfEntry from './isLastRowOfEntry';
export default function applyMergeRowsBorderStyles(TD, visualRow, instance) {
    const isExpanded = instance.getDataAtRowProp(visualRow, 'expand');
    if (!isExpanded)
        return;
    if ((isFirstRowOfEntry(visualRow, instance) && !isLastRowOfEntry(visualRow, instance)) ||
        !isFirstRowOfEntry(visualRow, instance)) {
        TD.classList.add('merge-rows');
    }
    if (isLastRowOfEntry(visualRow, instance)) {
        TD.classList.add('merge-rows-last');
    }
}
